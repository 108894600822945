import { Auth } from 'aws-amplify';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { baseUrl } from './apiEnvironment';
import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchBaseQueryError,
  FetchArgs,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';

export const TagTypes = {
  // This cast is to help typescript+rtk to infer the key+values as the ones listed only
  // otherwise it cannot know for sure if someone is going to add more keys dynamically
  User: 'User',
  Partner: 'Partner',
  Grants: 'Grants',
  Campaign: 'Campaign',
  CampaignImage: 'CampaignImage',
  CampaignPredefinedKeys: 'CampaignPredefinedKeys',
  CampaignTexts: 'CampaignTexts',
  LinkedEmail: 'LinkedEmail',
  ProposalsConfig: 'ProposalsConfig',
  Credits: 'Credits',
  Community: 'Community',
  CommunityPredefinedKeys: 'CommunityPredefinedKeys',
  CommunityMember: 'CommunityMember',
  CommunityImage: 'CommunityImage',
  CommunityEligibleEmail: 'CommunityEligibleEmail',
  CommunityTexts: 'CommunityTexts',
  ProgramApplications: 'ProgramApplications',
} as const;

export type AdminBuild = EndpointBuilder<
  // eslint-disable-next-line @typescript-eslint/ban-types
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
  keyof typeof TagTypes,
  'adminApi'
>;

export const getApiBaseQuery = () =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      const currentSession = await Auth.currentSession();
      const jwt = currentSession.getAccessToken().getJwtToken();
      headers.set('Authorization', `Bearer ${jwt}`);
      return headers;
    },
  });
