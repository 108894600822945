import { createApi } from '@reduxjs/toolkit/query/react';
import { getApiBaseQuery, AdminBuild, TagTypes } from './createApi';

import {
  ApexResponse,
  ApiAchRelationships,
  ApiTransfers,
  ApiUser,
  Partner,
  Investigation,
  SignupSource,
  StripeSubscriptions,
  Address,
  PaginationKey,
  PaginatedQuery,
  PaginatedResult,
  User,
  Withdrawal,
  GrantWithRequirements,
  ByUserIdFilter,
  LinkedEmail,
  CreditsSouceAccounts,
  ProposalsConfig,
  LatestBalance,
  InquiryReview,
  InvestigationReviewFields,
  FinancialAccount,
  FinancialAccountBalance,
  UserSearchQueryFilter,
  SearchInputParams,
  Community,
} from './types';
import { AnalysisError, EventInfo } from 'store/types';
import { GrantsImportJob } from './types/ImportJob';
import type { StartImportResponse } from './types/ImportJob';
import { toPaginationParams } from './utils';
import {
  createCampaign,
  getCampaign,
  getCampaigns,
  getCampaignPredefinedKeys,
  getCampaignsByPartner,
  getCampaignImages,
  uploadCampaignImage,
  updateCampaignTexts,
  getCampaignTexts,
  deleteCampaignImage,
  getCampaignGrantsImportJobs,
  startGrantsImport,
  getCampaignGrants,
  startCommunityProgram,
  pauseCommunityProgram,
  finishCommunityProgram,
  updateCampaign,
  getFormDefinition,
  getProgramApplications,
  getProgramApplication,
  acceptProgramApplication,
  rejectProgramApplication,
  programApplicationToApplied,
  getProgramsWithApplicationsToReview,
} from './campaignsApi';

import {
  getCommunity,
  getCommunityEligibleEmails,
  getCommunityEligibleEmailsImportJobs,
  getCommunityImages,
  getCommunities,
  getCommunitiesByPartner,
  getCommunitiesPredefinedKeys,
  deleteCommunityImage,
  startCommunityEligibleEmailsImport,
  searchCommunityByName,
  uploadCommunityImage,
  deleteCommunityEligibleEmail,
  createCommunity,
  deleteCommunity,
  getCommunityTexts,
  updateCommunityTexts,
  AddCommunityMember,
  updateCommunity,
} from './communityApi';

const getUserTransfers = (build: AdminBuild) =>
  build.query<ApiTransfers, { userId: string; startDate: string; endDate: string }>({
    query: ({ userId, startDate, endDate }) => ({
      url: `/admin/transfers/${userId}`,
      method: 'GET',
      params: { startDate, endDate },
    }),
  });

const getCreditSourceAccounts = (build: AdminBuild) =>
  build.query<CreditsSouceAccounts, void>({
    query: () => ({
      url: `/partnership/credits/credit-apex-accounts`,
      method: 'GET',
    }),
  });

const getCreditsImportJobs = (build: AdminBuild) =>
  build.query<PaginatedResult<GrantsImportJob, PaginationKey>, PaginatedQuery<PaginationKey>>({
    providesTags: [TagTypes.Credits],
    query: (pagination) => ({
      url: `/partnership/credits/credit-jobs`,
      method: 'GET',
      params: {
        ...toPaginationParams(pagination),
      },
    }),
  });

const getApexResponse = (build: AdminBuild) =>
  build.query<ApexResponse, string>({
    query: (userId) => `/admin/debug/${userId}/third-party/apex`,
  });

const getAchRelationships = (build: AdminBuild) =>
  build.query<ApiAchRelationships, string>({
    query: (userId) => `/admin/ach-relationships/${userId}`,
  });

const getUser = (build: AdminBuild) =>
  build.query<ApiUser, string>({
    providesTags: [TagTypes.User],
    query: (userId) => `/admin/user/${userId}`,
  });

const getUserFinancialAccount = (build: AdminBuild) =>
  build.query<FinancialAccount, string>({
    providesTags: [TagTypes.User],
    query: (userId) => `/admin/user/${userId}/financial-account`,
  });

const checkUserFinancialAccountBalance = (build: AdminBuild) =>
  build.query<FinancialAccountBalance, string>({
    providesTags: [TagTypes.User],
    query: (userId) => `/admin/user/${userId}/financial-account/check-balance`,
  });

const getStripeSubscriptions = (build: AdminBuild) =>
  build.query<StripeSubscriptions, string>({
    query: (customerId) => `/admin/debug/${customerId}/third-party/stripe`,
  });

const apexRejectedInvestigations = (build: AdminBuild) =>
  build.query<PaginatedResult<Investigation, PaginationKey>, PaginatedQuery<PaginationKey>>({
    query: (pagination) => ({
      url: '/admin/apex/rejected-investigations',
      method: 'GET',
      params: {
        ...toPaginationParams(pagination),
      },
    }),
  });

const searchUsers = (build: AdminBuild) =>
  build.query<PaginatedResult<User, PaginationKey>, UserSearchQueryFilter>({
    query: ({ email, ...pagination }) => ({
      url: '/admin/users/search',
      method: 'GET',
      params: {
        email,
        ...toPaginationParams(pagination),
      },
    }),
  });

const searchPartnersByName = (build: AdminBuild) =>
  build.query<Partner[], SearchInputParams>({
    query: ({ search, limit }) => ({
      url: '/partnership/partners/searchPartner',
      method: 'GET',
      params: {
        search,
        limit,
      },
    }),
    transformResponse: (response: { partners: Partner[] }) => response.partners,
  });

const getUserInvestigation = (build: AdminBuild) =>
  build.query<Investigation, string>({
    query: (userId) => `/admin/user/${userId}/investigation`,
  });

export type UserToReview = {
  PK: string;
  address: {
    city: string;
    postalCode: string;
    state: string;
    streetAddress: string;
    streetNameShort: string;
    streetNumber: string;
    unitDesignator: string;
    apartment: string;
  };
  firstName: string;
  lastName: string;
  birthday: string;
  comment: string;
  email: string;
  linkToPdf?: string;
  pdfLink?: string;
  socialSecurityNumber: string;
  updatedAt: string;
};

const getUsersToReview = (build: AdminBuild) =>
  build.query<PaginatedResult<UserToReview, PaginationKey>, PaginatedQuery<PaginationKey>>({
    providesTags: [TagTypes.User],
    query: (pagination) => ({
      url: `/admin/investigations/to-review`,
      method: 'GET',
      params: {
        ...toPaginationParams(pagination),
      },
    }),
  });

const getUserToReview = (build: AdminBuild) =>
  build.query<InquiryReview, string>({
    query: (userId) => `/admin/user/${userId}/investigation-to-review`,
    providesTags: [TagTypes.User],
  });

const reviewUserInvestigation = (build: AdminBuild) =>
  build.mutation<void, { userId: string; investigationReviewFields: InvestigationReviewFields }>({
    query: ({ userId, investigationReviewFields }) => ({
      url: `/admin/user/${userId}/investigation/review`,
      method: 'POST',
      body: investigationReviewFields,
    }),
    invalidatesTags: [TagTypes.User],
  });

const rejectUserInvestigation = (build: AdminBuild) =>
  build.mutation<void, { userId: string; comment: string }>({
    query: ({ userId, comment }) => ({
      url: `/admin/user/${userId}/investigation/reject`,
      method: 'POST',
      body: { comment },
    }),
    invalidatesTags: [TagTypes.User],
  });

const resetLastOnboardingStep = (build: AdminBuild) =>
  build.mutation<void, { userId: string }>({
    query: ({ userId }) => ({
      url: `/admin/user/${userId}/reset-onboarding-to-step-three`,
      method: 'POST',
    }),
    invalidatesTags: [TagTypes.User],
  });

const getUserGrants = (build: AdminBuild) =>
  build.query<PaginatedResult<GrantWithRequirements, PaginationKey>, ByUserIdFilter>({
    providesTags: [TagTypes.Grants],
    query: ({ userId, ...pagination }) => ({
      url: `/partnership/users/${userId}/grants`,
      method: 'GET',
      params: {
        ...toPaginationParams(pagination),
      },
    }),
  });

const getUserSignupSource = (build: AdminBuild) =>
  build.query<SignupSource, string>({
    query: (userId) => `/admin/user/${userId}/signupSource`,
  });

const getUserLinkedEmails = (build: AdminBuild) =>
  build.query<PaginatedResult<LinkedEmail, PaginationKey>, ByUserIdFilter>({
    providesTags: [TagTypes.LinkedEmail],
    query: ({ userId, ...pagination }) => ({
      url: `/admin/user/${userId}/linked-emails`,
      method: 'GET',
      params: {
        ...toPaginationParams(pagination),
      },
    }),
  });

const getUserCommunities = (build: AdminBuild) =>
  build.query<PaginatedResult<Community, PaginationKey>, ByUserIdFilter>({
    providesTags: [TagTypes.LinkedEmail],
    query: ({ userId, ...pagination }) => ({
      url: `/partnership/users/${userId}/communities`,
      method: 'GET',
      params: {
        ...toPaginationParams(pagination),
      },
    }),
  });

const deleteLinkedEmail = (build: AdminBuild) =>
  build.mutation<void, LinkedEmail>({
    query: (linkedEmail) => ({
      url: `/admin/user/${linkedEmail.userId}/linked-emails/${encodeURIComponent(linkedEmail.SK)}`,
      method: 'DELETE',
    }),
    invalidatesTags: [TagTypes.LinkedEmail],
  });

const getUserLatestBalance = (build: AdminBuild) =>
  build.query<LatestBalance, string>({
    query: (apexAccount) => `/admin/account/${apexAccount}/latestBalance`,
  });

const getUserAnalysisErrors = (build: AdminBuild) =>
  build.query<Array<AnalysisError>, string>({
    query: (userId) => `/admin/analysis-errors/${userId}`,
  });

const getUserApexEvents = (build: AdminBuild) =>
  build.query<Array<EventInfo>, string>({
    query: (userId) => `/admin/event-info/${userId}`,
  });

const getPartners = (build: AdminBuild) =>
  build.query<PaginatedResult<Partner, PaginationKey>, PaginatedQuery<PaginationKey>>({
    providesTags: [TagTypes.Partner],
    query: (pagination) => ({
      url: '/partnership/partners',
      method: 'GET',
      params: {
        ...toPaginationParams(pagination),
      },
    }),
  });

const getPartner = (build: AdminBuild) =>
  build.query<Partner, string>({
    providesTags: [TagTypes.Partner],
    query: (partnerId) => `/partnership/partners/${partnerId}`,
    transformResponse: (response: { partner: Partner }) => response.partner,
  });

type StartCreditsImportResponse = StartImportResponse & {
  job: GrantsImportJob;
};
const startCreditsImport = (build: AdminBuild) =>
  build.mutation<
    StartCreditsImportResponse,
    { apexAccount: string; description: string; originalFileName: string }
  >({
    query: ({ apexAccount, description, originalFileName }) => ({
      url: `/partnership/credits/import-job`,
      method: 'POST',
      body: { originalFileName, apexAccount, description },
    }),
    invalidatesTags: [TagTypes.Credits],
  });

type PendingWithdrawalsFilter = PaginatedQuery<PaginationKey> & {
  to: string;
};
const getPendingWithdrawals = (build: AdminBuild) =>
  build.query<PaginatedResult<Withdrawal, PaginationKey>, PendingWithdrawalsFilter>({
    query: ({ to, ...pagination }) => ({
      url: `/admin/transfers/pending-withdrawals`,
      method: 'GET',
      params: {
        ...toPaginationParams(pagination),
        to,
      },
    }),
  });

const closeAccount = (build: AdminBuild) =>
  build.mutation<void, string>({
    query: (userId) => ({
      url: `/admin/close-account/${userId}`,
      method: 'POST',
    }),
    invalidatesTags: [TagTypes.User],
  });

const initIntermediateCloseAccount = (build: AdminBuild) =>
  build.mutation<void, string>({
    query: (userId) => ({
      url: `/admin/init-intermediate-close-account/${userId}`,
      method: 'POST',
    }),
    invalidatesTags: [TagTypes.User],
  });

const finishIntermediateCloseAccount = (build: AdminBuild) =>
  build.mutation<void, string>({
    query: (userId) => ({
      url: `/admin/finish-intermediate-close-account/${userId}`,
      method: 'POST',
    }),
    invalidatesTags: [TagTypes.User],
  });

const resetAccount = (build: AdminBuild) =>
  build.mutation<void, string>({
    query: (userId) => ({
      url: `/admin/user/${userId}/reset-onboarding`,
      method: 'POST',
    }),
    invalidatesTags: [TagTypes.User],
  });

const updateUserAddress = (build: AdminBuild) =>
  build.mutation<void, { userId: string; address: Address }>({
    query: ({ userId, address }) => ({
      url: `/admin/update-address/${userId}`,
      method: 'POST',
      body: { address },
    }),
    invalidatesTags: [TagTypes.User],
  });

const updateUserEmail = (build: AdminBuild) =>
  build.mutation<void, { userId: string; email: string }>({
    query: ({ userId, email }) => ({
      url: `/admin/update-email/${userId}`,
      method: 'POST',
      body: { email },
    }),
    invalidatesTags: [TagTypes.User],
  });

const deleteGrant = (build: AdminBuild) =>
  build.mutation<void, string>({
    query: (grantId) => ({
      url: `/partnership/grants/${encodeURIComponent(grantId)}`,
      method: 'DELETE',
    }),
    invalidatesTags: [TagTypes.Grants, TagTypes.ProgramApplications],
  });

const inactivateGrant = (build: AdminBuild) =>
  build.mutation<void, string>({
    query: (grantId) => ({
      url: `/partnership/grants/${encodeURIComponent(grantId)}/inactivate`,
      method: 'PUT',
    }),
    invalidatesTags: [TagTypes.Grants],
  });

const createPartner = (build: AdminBuild) =>
  build.mutation<void, { name: string; code: string; creditApexContraAccount: string }>({
    query: ({ name, code, creditApexContraAccount }) => ({
      url: `/partnership/partners`,
      method: 'POST',
      body: { name, code, creditApexContraAccount },
    }),
    invalidatesTags: [TagTypes.Partner],
  });

const updatePartner = (build: AdminBuild) =>
  build.mutation<
    void,
    { partnerId: string; name: string; code: string; creditApexContraAccount: string }
  >({
    query: ({ partnerId, name, code, creditApexContraAccount }) => ({
      url: `/partnership/partners/${partnerId}`,
      method: 'PUT',
      body: { name, code, creditApexContraAccount },
    }),
    invalidatesTags: [TagTypes.Partner],
  });

const getProposalsConfig = (build: AdminBuild) =>
  build.query<ProposalsConfig, void>({
    providesTags: [TagTypes.ProposalsConfig],
    query: () => ({
      url: '/admin/proposals/config',
      method: 'GET',
    }),
  });

const ignoreAccount = (build: AdminBuild) =>
  build.mutation<void, string>({
    query: (account) => ({
      url: '/admin/proposals/config/add-ignore-accounts',
      method: 'PUT',
      body: { apexAccounts: [account] },
    }),
    invalidatesTags: [TagTypes.ProposalsConfig],
  });

const deleteIgnoredAccount = (build: AdminBuild) =>
  build.mutation<void, string>({
    query: (account) => ({
      url: '/admin/proposals/config/remove-ignore-accounts',
      method: 'PUT',
      body: { apexAccounts: [account] },
    }),
    invalidatesTags: [TagTypes.ProposalsConfig],
  });

const adminEndpoints = (build: AdminBuild) => ({
  apexRejectedInvestigations: apexRejectedInvestigations(build),
  closeAccount: closeAccount(build),
  finishIntermediateCloseAccount: finishIntermediateCloseAccount(build),
  startGrantsImport: startGrantsImport(build),
  startCreditsImport: startCreditsImport(build),
  startCommunityEligibleEmailsImport: startCommunityEligibleEmailsImport(build),
  uploadCommunityImage: uploadCommunityImage(build),
  uploadCampaignImage: uploadCampaignImage(build),
  updateCommunityTexts: updateCommunityTexts(build),
  deleteCommunityImage: deleteCommunityImage(build),
  checkUserFinancialAccountBalance: checkUserFinancialAccountBalance(build),
  getAchRelationships: getAchRelationships(build),
  getApexResponse: getApexResponse(build),
  getCampaign: getCampaign(build),
  getCampaignImages: getCampaignImages(build),
  getCampaignGrants: getCampaignGrants(build),
  getCommunityEligibleEmails: getCommunityEligibleEmails(build),
  getCampaignGrantsImportJobs: getCampaignGrantsImportJobs(build),
  getCreditsImportJobs: getCreditsImportJobs(build),
  getCommunityEligibleEmailsImportJobs: getCommunityEligibleEmailsImportJobs(build),
  getCommunityTexts: getCommunityTexts(build),
  getPartner: getPartner(build),
  getCampaignsByPartner: getCampaignsByPartner(build),
  getPartners: getPartners(build),
  getCommunities: getCommunities(build),
  getCommunityImages: getCommunityImages(build),
  getCommunity: getCommunity(build),
  getPendingWithdrawals: getPendingWithdrawals(build),
  getProgramsWithApplicationsToReview: getProgramsWithApplicationsToReview(build),
  getStripeSubscriptions: getStripeSubscriptions(build),
  getUser: getUser(build),
  getUserAnalysisErrors: getUserAnalysisErrors(build),
  getUserApexEvents: getUserApexEvents(build),
  getUserGrants: getUserGrants(build),
  getUserInvestigation: getUserInvestigation(build),
  getUserFinancialAccount: getUserFinancialAccount(build),
  getUserLatestBalance: getUserLatestBalance(build),
  getUserSignupSource: getUserSignupSource(build),
  getUserLinkedEmails: getUserLinkedEmails(build),
  getUserTransfers: getUserTransfers(build),
  getUserCommunities: getUserCommunities(build),
  getCreditSourceAccounts: getCreditSourceAccounts(build),
  inactivateGrant: inactivateGrant(build),
  initIntermediateCloseAccount: initIntermediateCloseAccount(build),
  resetAccount: resetAccount(build),
  updateUserAddress: updateUserAddress(build),
  updateUserEmail: updateUserEmail(build),
  getCampaignTexts: getCampaignTexts(build),
  updateCampaignTexts: updateCampaignTexts(build),
  searchUsers: searchUsers(build),
  deleteGrant: deleteGrant(build),
  deleteCommunityEligibleEmail: deleteCommunityEligibleEmail(build),
  deleteLinkedEmail: deleteLinkedEmail(build),
  updateCommunity: updateCommunity(build),
  deleteCommunity: deleteCommunity(build),
  createPartner: createPartner(build),
  updatePartner: updatePartner(build),
  createCommunity: createCommunity(build),
  createCampaign: createCampaign(build),
  getProposalsConfig: getProposalsConfig(build),
  ignoreAccount: ignoreAccount(build),
  deleteIgnoredAccount: deleteIgnoredAccount(build),
  getUsersToReview: getUsersToReview(build),
  getUserToReview: getUserToReview(build),
  reviewUserInvestigation: reviewUserInvestigation(build),
  rejectUserInvestigation: rejectUserInvestigation(build),
  resetLastOnboardingStep: resetLastOnboardingStep(build),
  searchPartnersByName: searchPartnersByName(build),
  searchCommunityByName: searchCommunityByName(build),
  deleteCampaignImage: deleteCampaignImage(build),
  getCommunitiesPredefinedKeys: getCommunitiesPredefinedKeys(build),
  getCampaignPredefinedKeys: getCampaignPredefinedKeys(build),
  getCommunitiesByPartner: getCommunitiesByPartner(build),
  getCampaigns: getCampaigns(build),
  AddCommunityMember: AddCommunityMember(build),
  startCommunityProgram: startCommunityProgram(build),
  pauseCommunityProgram: pauseCommunityProgram(build),
  finishCommunityProgram: finishCommunityProgram(build),
  updateCampaign: updateCampaign(build),
  getFormDefinition: getFormDefinition(build),
  getProgramApplication: getProgramApplication(build),
  getProgramApplications: getProgramApplications(build),
  acceptProgramApplication: acceptProgramApplication(build),
  rejectProgramApplication: rejectProgramApplication(build),
  programApplicationToApplied: programApplicationToApplied(build),
});

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: getApiBaseQuery(),
  tagTypes: Object.values(TagTypes),
  endpoints: adminEndpoints,
});

export const {
  useApexRejectedInvestigationsQuery,
  useCloseAccountMutation,
  useStartGrantsImportMutation,
  useStartCreditsImportMutation,
  useStartCommunityEligibleEmailsImportMutation,
  useGetCampaignGrantsImportJobsQuery,
  useFinishIntermediateCloseAccountMutation,
  useLazyCheckUserFinancialAccountBalanceQuery,
  useGetAchRelationshipsQuery,
  useGetApexResponseQuery,
  useGetCampaignGrantsQuery,
  useGetProgramApplicationQuery,
  useGetProgramApplicationsQuery,
  useGetFormDefinitionQuery,
  useLazyGetFormDefinitionQuery,
  useGetCommunityEligibleEmailsQuery,
  useGetCampaignQuery,
  useGetCampaignsByPartnerQuery,
  useGetPartnerQuery,
  useGetPartnersQuery,
  useGetCommunitiesQuery,
  useGetCommunityQuery,
  useGetCommunityTextsQuery,
  useGetPendingWithdrawalsQuery,
  useGetProgramsWithApplicationsToReviewQuery,
  useGetStripeSubscriptionsQuery,
  useGetUserAnalysisErrorsQuery,
  useGetUserApexEventsQuery,
  useGetUserGrantsQuery,
  useGetUserFinancialAccountQuery,
  useGetUserInvestigationQuery,
  useGetUserLatestBalanceQuery,
  useGetUserQuery,
  useGetUserSignupSourceQuery,
  useGetUserLinkedEmailsQuery,
  useGetUserCommunitiesQuery,
  useGetUserTransfersQuery,
  useGetCreditSourceAccountsQuery,
  useGetCommunityEligibleEmailsImportJobsQuery,
  useGetCreditsImportJobsQuery,
  useInactivateGrantMutation,
  useInitIntermediateCloseAccountMutation,
  useResetAccountMutation,
  useUpdateUserAddressMutation,
  useUpdateUserEmailMutation,
  useSearchUsersQuery,
  useDeleteGrantMutation,
  useDeleteCommunityEligibleEmailMutation,
  useDeleteLinkedEmailMutation,
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
  useCreateCampaignMutation,
  useCreateCommunityMutation,
  useUpdateCommunityMutation,
  useDeleteCommunityMutation,
  useGetProposalsConfigQuery,
  useIgnoreAccountMutation,
  useDeleteIgnoredAccountMutation,
  useGetUsersToReviewQuery,
  useGetUserToReviewQuery,
  useReviewUserInvestigationMutation,
  useRejectUserInvestigationMutation,
  useResetLastOnboardingStepMutation,
  useSearchPartnersByNameQuery,
  useSearchCommunityByNameQuery,
  useUploadCommunityImageMutation,
  useUploadCampaignImageMutation,
  useDeleteCommunityImageMutation,
  useGetCommunityImagesQuery,
  useGetCampaignImagesQuery,
  useDeleteCampaignImageMutation,
  useUpdateCampaignTextsMutation,
  useUpdateCommunityTextsMutation,
  useGetCampaignTextsQuery,
  useGetCampaignPredefinedKeysQuery,
  useGetCommunitiesPredefinedKeysQuery,
  useGetCommunitiesByPartnerQuery,
  useGetCampaignsQuery,
  useAddCommunityMemberMutation,
  useStartCommunityProgramMutation,
  usePauseCommunityProgramMutation,
  useFinishCommunityProgramMutation,
  useUpdateCampaignMutation,
  useAcceptProgramApplicationMutation,
  useRejectProgramApplicationMutation,
  useProgramApplicationToAppliedMutation,
} = adminApi;
